import React, { useState } from "react";
import ReactDOM from "react-dom";
import Sidebar from "react-sidebar";
import { Icon } from "@iconify/react";

function BoneyardSidebar({ currentPage }) {
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const onSidebarOpen = (e) => {
    e.preventDefault();

    setSidebarOpen((prevState) => !prevState);
  };

  function renderSidebarContent() {
    return (
      <div className="sidebar-content">
        <a className="sidebar-close" onClick={onSidebarOpen}>
          <Icon icon="bi:x-lg" />
        </a>
        <a
          className={`sidebar-link ${
            currentPage === "pages_home" ? "active" : ""
          }`}
          href="/"
        >
          Home
        </a>

        <a
          className="sidebar-link"
          href="https://medium.com/@The_Boneyard/the-boneyard-28cd1502ed0a"
          target="_blank"
        >
          Flight Plan
        </a>

        <a
          className={`sidebar-link ${
            currentPage === "nfts_index" ? "active" : ""
          }`}
          href="/nfts"
        >
          Flight Line
        </a>
        <a
          className={`sidebar-link ${
            currentPage === "pages_about" ? "active" : ""
          }`}
          href="/about"
        >
          About
        </a>
        {window.gon && !window.gon.production && (
          <a
            className={`sidebar-link ${
              currentPage === "pages_faq" ? "active" : ""
            }`}
            href="/faq"
          >
            FAQs
          </a>
        )}
        <a
          className={`sidebar-link ${
            currentPage === "pages_contact" ? "active" : ""
          }`}
          href="/contact"
        >
          Contact
        </a>
      </div>
    );
  }

  return (
    <div id="boneyard-sidebar-component">
      <Sidebar
        rootClassName="root-class"
        sidebarClassName="sidebar-class"
        contentClassName="content-class"
        overlayClassName="overlay-class"
        sidebar={renderSidebarContent()}
        open={sidebarOpen}
        onSetOpen={onSidebarOpen}
        pullRight
        styles={{
          sidebar: {
            position: "fixed",
          },
          root: {
            position: "undefined",
          },
          content: {
            position: "undefined",
            top: "undefined",
            left: "undefined",
            right: "undefined",
            bottom: "undefined",
          },
        }}
      />
      <a onClick={onSidebarOpen}>
        <Icon icon="charm:menu-hamburger" />
      </a>
    </div>
  );
}

export default BoneyardSidebar;

document.addEventListener("DOMContentLoaded", () => {
  const mountPoint = document.getElementById("boneyard-sidebar-mount-point");

  if (mountPoint) {
    const { currentPage } = mountPoint.dataset;
    ReactDOM.render(<BoneyardSidebar currentPage={currentPage} />, mountPoint);
  }
});
